import './styles.css'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import { EditorProvider, FloatingMenu, BubbleMenu, useCurrentEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

const MenuBar = () => {
  const { editor } = useCurrentEditor()

  if (!editor) {
    return null
  }

  return (
    <div className='upper-menus'>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
      >
        <i className="ri-h-1"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        <i className="ri-h-2"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
      >
        <i className="ri-h-3"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
      >
        <i className="ri-h-4"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        <i className="ri-list-unordered"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <i className="ri-list-ordered-2"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive('codeBlock') ? 'is-active' : ''}
      >
        <i className="ri-code-block"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
      >
        <i className="ri-double-quotes-r"></i>
      </button>
      <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
      <i className="ri-separator"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().undo().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .undo()
            .run()
        }
      >
        <i className="ri-arrow-go-back-line"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .redo()
            .run()
        }
      >
        <i className="ri-arrow-go-forward-line"></i>
      </button>
    </div>
  )
}

const MyBubble = () => {
  const { editor } = useCurrentEditor()

  return (
    <div className='bubble-menus'>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <i className="ri-bold"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleItalic()
            .run()
        }
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        <i className="ri-italic"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleStrike()
            .run()
        }
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        <i className="ri-strikethrough"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleCode()
            .run()
        }
        className={editor.isActive('code') ? 'is-active' : ''}
      >
        <i className="ri-code-line"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().setColor('#958DF1').run()}
        className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'is-active' : ''}
      >
        紫
      </button>
    </div>
  )
}

const MyFloating = () => {
  const { editor } = useCurrentEditor()

  return (
    <div className='floating-menus'>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
      >
        <i className="ri-h-1"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        <i className="ri-h-2"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
      >
        <i className="ri-h-3"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
      >
        <i className="ri-h-4"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        <i className="ri-list-unordered"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <i className="ri-list-ordered-2"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive('codeBlock') ? 'is-active' : ''}
      >
        <i className="ri-code-block"></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
      >
        <i className="ri-double-quotes-r"></i>
      </button>
    </div>
  )
}

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
]

const content = `
<h2>
  おつかれさま！
</h2>
<p>
  こちらは<strong>Tiptap</strong>の<em>基本</em> サンプルです。当然、テキストエディタに期待している基本的な全てのスタイルに対応しています。リストも見て：
</p>
<ul>
  <li>
    リストの１つめです、、
  </li>
  <li>
    そしてこれが２つ目
  </li>
</ul>
<p>
  いい感じ？ 全て編集可能です。 コードブロックもやって見ましょう：
</p>
<pre><code class="language-css">body {
  display: none;
}</code></pre>
<p>
これは氷山の一角。試しにちょっとクリックしてみてください。他の例もお忘れなく。
</p>
<blockquote>
  すごい！ナイスワーク👏
  <br />
  — 母より
</blockquote>
`

const EditorJSONPreview = () => {
  const { editor } = useCurrentEditor()

  return (
    <div className='json-preview'>
      <h3>getJSON</h3>
      <pre>
        {JSON.stringify(editor.getJSON(), null, 2)}
      </pre>
    </div>
  )
}

const EditorHTMLPreview = () => {
  const { editor } = useCurrentEditor()

  return (
    <div className='html-preview'>
      <h3>getHTML</h3>
      <pre>
        {editor.getHTML()}
      </pre>
    </div>
  )
}

const Tiptap = () => {
  return (
      <EditorProvider slotBefore={<MenuBar />} extensions={extensions} content={content}>
        <FloatingMenu><MyFloating /></FloatingMenu>
        <BubbleMenu><MyBubble /></BubbleMenu>
        <div className='previews'>
          <EditorHTMLPreview />
          <EditorJSONPreview />
        </div>
      </EditorProvider>
  )
}

export default Tiptap